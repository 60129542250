import { Controller } from "@hotwired/stimulus"

// Check if either "Keep" or "Return" is selected for all items to enable the submit button
// If not, show the validation message
export default class extends Controller {
  static targets = [
    "customerStatus",
    "validationMessage",
    "submitButton",
    "buyAllSubmitButton"
  ]

  connect () {
    this.update()
  }

  disableAndSubmit(event) {
    const clickedButton = event.target

    this.submitButtonTarget.disabled = true
    this.buyAllSubmitButtonTargets.forEach((button) => {
      button.disabled = true
    })

    clickedButton.value = `${clickedButton.value} ...`

    // Allow form submission
    event.target.closest("form").submit()
  }

  update() {
    const allSelected = this.allHaveSelectedRadio(this.customerStatusTargets)
    this.validationMessageTarget.hidden = allSelected
    this.submitButtonTarget.disabled = !allSelected
  }

  allHaveSelectedRadio(elements) {
    return elements.every(item =>
      Array.from(item.querySelectorAll('input[type="radio"]')).some(radioButton => radioButton.checked)
    )
  }
}
